import React from 'react';
import { navigate, graphql } from 'gatsby';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import * as Accessa from 'accessa';
import SEOHelmet from '../components/SEOHelmet';
import PageBody from '../components/PageBody';
import ProductsHeader from '../components/ProductsHeader';

// eslint-disable-next-line
const ServicesArchive = ({ data: { page } }) => {
  const services = get(page, 'services', []);
  const body = get(page, 'body.raw', '');

  return (
    <Box>
      <SEOHelmet
        title={
          get(page, 'seo.title') ||
          get(page, 'banner.title')
        }
        description={get(
          page,
          'seo.metaDescription.metaDescription',
          '',
        )}
      />
      <ProductsHeader
        title={get(page, 'banner.title')}
        image={get(page, 'banner.image')}
      >
        <PageBody body={body} />
        {services && (
          <Container style={{ paddingBottom: '2rem' }}>
            <Accessa.Features.Field
              spacing={1}
              lists={services.map((item) => ({
                onClick: () => navigate(item.to),
                description: get(item, 'excerpt.excerpt'),
                ...Object.assign(get(item, 'image', {}), {
                  style: {
                    backgroundColor: '#FFF',
                    height: '15rem',
                    margin: '.15rem',
                  },
                  imgStyle: {
                    objectFit: 'contain !important',
                  },
                }),
                ...item,
              }))}
            />
          </Container>
        )}
      </ProductsHeader>
    </Box>
  );
};

export default ServicesArchive;

export const query = graphql`
  query getServicesArchive {
    page: contentfulPage(
      contentful_id: { eq: "QBySFzJiTzRrcEt9sqQIH" }
    ) {
      contentful_id
      description {
        description
      }
      banner {
        title
        image {
          alt: title
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid
          }
        }
      }
      body {
        raw
      }
      services {
        title
        excerpt {
          excerpt
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        to
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
      }
    }
  }
`;
