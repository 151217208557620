import React from 'react';
import { RichText } from 'gatsby-theme-q3/src/components';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import { size } from 'lodash';

const useStyle = makeStyles((theme) => ({
  columns: ({ len }) => ({
    marginBottom: theme.spacing(5),
    ...(len < 5000
      ? {
          marginTop: theme.spacing(-2),
          textAlign: 'center',
          maxWidth: 750,
        }
      : {
          marginTop: theme.spacing(5),
        }),
  }),
}));

// eslint-disable-next-line
const PageBody = ({ body }) => {
  const cls = useStyle({
    len: size(body),
  });

  return (
    <Container
      component="section"
      className={cls.columns}
      maxWidth="md"
    >
      <RichText json={JSON.parse(body)} />
    </Container>
  );
};

export default PageBody;
