import React from 'react';
import Image from 'gatsby-image';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import {
  Container,
  Typography,
  Box,
  Fade,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const ProductsHeader = ({
  children,
  description,
  title,
  image,
}) => (
  <Box component="main" bgcolor="background.paper">
    {image && (
      <Fade in>
        <Image
          {...image}
          style={{
            height: '25vh',
            marginBottom: '2rem',
          }}
        />
      </Fade>
    )}
    <Container component="section" maxWidth="md">
      <Box py={3} textAlign="center">
        <Typography variant="h1">{title}</Typography>
        {description && (
          <Box mt={1} mx="auto" width={750} maxWidth="100%">
            <RichText json={JSON.parse(description)} />
          </Box>
        )}
      </Box>
    </Container>
    {children}
  </Box>
);

ProductsHeader.defaultProps = {
  image: null,
  children: null,
  description: null,
};

ProductsHeader.propTypes = {
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ProductsHeader;
